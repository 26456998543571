export const FETCH_ANALYSIS = "FETCH_ANALYSIS";
export const FETCH_ANALYSIS_FULFILLED = "FETCH_ANALYSIS_FULFILLED";
export const FETCH_ANALYSIS_REJECTED = "FETCH_ANALYSIS_REJECTED";

export const FETCH_TOPICLIST = "FETCH_TOPICLIST";
export const FETCH_TOPICLIST_FULFILLED = "FETCH_TOPICLIST_FULFILLED";
export const FETCH_TOPICLIST_REJECTED = "FETCH_TOPICLIST_REJECTED";

export const FETCH_CHATEM = "FETCH_CHATEM";
export const FETCH_CHATEM_FULFILLED = "FETCH_CHATEM_FULFILLED";
export const FETCH_CHATEM_REJECTED = "FETCH_CHATEM_REJECTED";

export const FETCH_CHATLC = "FETCH_CHATLC";
export const FETCH_CHATLC_FULFILLED = "FETCH_CHATLC_FULFILLED";
export const FETCH_CHATLC_REJECTED = "FETCH_CHATLC_REJECTED";
